






















































import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import GlobalHelper from "../../helpers/globalHelper";
import StatusDialog from "@/components/Dialogs/StatusDialog.vue";
// eslint-disable-next-line no-unused-vars
import { StatusLog } from "../../models/statuslog";
import ErrorHelper from "../../helpers/errorHelper";

export default Vue.extend({
  components: {
    StatusDialog
  },
  data(): {
    headers: Array<any>,
    tableData: Array<StatusLog>,
    page: number,
    pageSize: number,
    clockId: number | undefined,
    dataLoading: boolean,
    totalItems: number,
    showDetails: boolean
    selectedLog: any;
    } {
    return {
      headers: [
        { text: "Job", value: "SourceAppId" },
        { text: "Status", value: "Status" },
        { text: "Processed Date", value: "Processed" },
        { text: "User Name", value: "UserId" }
      ],
      page: 1,
      pageSize: 20,
      tableData: [],
      clockId: undefined,
      dataLoading: false,
      totalItems: 0,
      showDetails: false,
      selectedLog: ""
    };
  },
  computed: {
    ...mapState(["activityAutoRefresh", "activeCompany"])
  },
  mounted() {
    this.refreshStatus();
    this.setAutoRefresh(true);
  },
  methods: {
    ...mapMutations(["changeActivityAutoRefresh", "changeStatusDialogState", "changeStatusDialogInfo"]),
    formatDate(date: string): string {
      const dateTime = date.match(/[0-9]+/);
      return dateTime != null ? new Date(parseInt(dateTime[0])).toLocaleDateString() : "";
    },
    getDayEnd(date: string): string {
      var _date = new Date(date);
      _date.setUTCDate(_date.getUTCDate() + 1);
      _date.setUTCHours(0);
      _date.setUTCMinutes(0);
      _date.setUTCSeconds(0);
      return _date.toISOString();
    },
    dateToString(strDate: string): string {
      var date = new Date(strDate);
      if (date instanceof Date && !isNaN(date.getTime())) {
        return date.toISOString().replace("T", " ");
      }
      return new Date().toISOString().replace("T", " ");
    },
    isLoaded(label: string): boolean {
      return label === "Processed";
    },
    isLoadedWithLow(label: string): boolean {
      return /Processed[ -]+(Low|Information|Ignore|Prompt Message)/.test(label);
    },
    isLoadedWithError(label: string): boolean {
      return /Processed[ -]+(High|Error|Fail|High - CDER)/.test(label);
    },
    isLoadedWithMedium(label: string): boolean {
      return /Processed[ -]+(Medium|Best Practice|Warning)/.test(label);
    },
    isFailedLoad(label: string): boolean {
      return label.startsWith("Failed");
    },
    setAutoRefresh(autoRefresh: boolean): void {
      if (autoRefresh) {
        this.clockId = setInterval(this.refreshStatus, 8000);
      } else {
        clearInterval(this.clockId);
      }
    },
    openDetails(logId: string): void {
      this.changeStatusDialogState(true);
      this.changeStatusDialogInfo(logId);
    },
    async refreshStatus(): Promise<void> {
      if (!this.activeCompany) {
        return;
      }
      this.dataLoading = true;
      const params : any = {
        companyId: this.activeCompany.Id,
        startDate: this.dateToString(this.startDate),
        endDate: this.dateToString(this.getDayEnd(this.endDate)),
        currentUserOnly: this.userFilter === "current",
        appFilter: this.appFilter,
        startIndex: (this.page - 1) * this.pageSize,
        endIndex: ((this.page - 1) * this.pageSize) + this.pageSize
      };
      const data = await GlobalHelper.http("api/Status/GetStatus" + GlobalHelper.toQueryString(params), ErrorHelper.StatusGetError);
      this.totalItems = data.Total;
      this.tableData = data.Results.map((log: any) => StatusLog.FromObject(log));
      this.dataLoading = false;
    }
  },
  watch: {
    page(): void {
      this.refreshStatus();
      if (this.page !== 1) {
        this.changeActivityAutoRefresh(false);
      } else {
        this.changeActivityAutoRefresh(true);
      }
    },
    startDate(): void {
      this.refreshStatus();
      this.page = 1;
    },
    endDate(): void {
      this.refreshStatus();
      this.page = 1;
    },
    userFilter(): void {
      this.refreshStatus();
    },
    appFilter(): void {
      this.refreshStatus();
      this.page = 1;
    },
    activityAutoRefresh(): void {
      this.refreshStatus();
      this.setAutoRefresh(this.activityAutoRefresh);
    }
  },
  props: {
    startDate: {
      type: Date
    },
    endDate: {
      type: Date
    },
    userFilter: {
      type: String
    },
    appFilter: {
      type: String
    },
    autoRefresh: {
      type: Boolean
    }
  }
});
