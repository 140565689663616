import Vue from "vue";
import App from "../App.vue";
import store from "../store";
import router from "@/router";
import vuetify from "../plugins/vuetify";
import init from "common-vue-components/init";

Vue.config.productionTip = false;

declare global {
  interface Window {
    version: string;
    baseUrl: string;
    companyName: string;
  }
}

init();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
