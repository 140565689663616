
















import Vue from "vue";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState(["contactDialogState"])
  },
  data(): {
        contactDialog: boolean;
        contactInfos: any[];
        } {
    return {
      contactDialog: false,
      contactInfos: [
        {
          href: "mailto:support@certara.com",
          icon: "mdi-email-outline",
          title: "Email Support: support@certara.com"
        },
        {
          href: "tel:+19198524620",
          icon: "mdi-phone-outline",
          title: "Call Support: +1-919-852-4620"
        }
      ]
    };
  },
  methods: {
    ...mapMutations(["changeContactDialogState"])
  },
  watch: {
    contactDialogState(): void {
      this.contactDialog = this.contactDialogState;
    },
    contactDialog(): void {
      if (this.contactDialog === false) {
        this.changeContactDialogState();
      }
    }
  }
});
