







































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
// eslint-disable-next-line no-unused-vars
import GlobalHelper from "../../helpers/globalHelper";
import ErrorHelper from "../../helpers/errorHelper";
// eslint-disable-next-line no-unused-vars
import { ConfigurationModel } from "@/models/configurationModel";
import { ProcessOptions } from "@/helpers/enums";

export default Vue.extend({
  computed: {
    ...mapState(["selectedFolders", "currentLocation", "queueData", "configurationInfo", "activeCompany"])
  },
  data(): {
        checkedOptions: string[],
        formats: string[],
        selectedFormat: string,
        processMenuState: boolean,
        removeButtonDisabled: boolean,
        formatKnown: boolean,
        configModel: ConfigurationModel,
        ProcessOptionsEnums: ProcessOptions
        } {
    return {
      checkedOptions: [],
      formats: [
        "eCTD",
        "NeeS Application",
        "NeeS Sequence"
      ],
      selectedFormat: "eCTD",
      processMenuState: false,
      removeButtonDisabled: false,
      formatKnown: false,
      configModel: ConfigurationModel.FromObject({}),
      ProcessOptionsEnums: Object.assign(ProcessOptions)
    };
  },
  methods: {
    ...mapActions(["getQueueData", "getConfigData"]),
    async loadAppOrSeq(): Promise<void> {
      if (!this.activeCompany) {
        return;
      }

      var loadPreferences = {
        folderPath: GlobalHelper.ConstructPathFromCurrentLocation(),
        selectedFormat: this.selectedFormat,
        processXml: this.configModel.ValidateXMLStructure,
        processPdfs: this.configModel.ValidatePdfs,
        calculateChecksums: this.configModel.ValidateChecksums
      };
      await GlobalHelper.httpWithObject(`api/Loading/LoadFolder?companyId=${this.activeCompany.Id}`,
        { ...this.selectedFolders[0], ...loadPreferences },
        ErrorHelper.EnqueueProcessError,
        this.enqueueSuccess
      );
    },
    async unloadAppOrSeq(): Promise<void> {
      if (!this.activeCompany) {
        return;
      }

      await GlobalHelper.httpWithObject(`api/Loading/UnloadFolder?companyId=${this.activeCompany.Id}`, this.selectedFolders[0], ErrorHelper.EnqueueRemoveError, this.enqueueSuccess);
    },
    anyProcessOptionsChecked(): boolean {
      return this.configModel.ValidatePdfs ||
        this.configModel.ValidateChecksums ||
        this.configModel.ValidateXMLStructure;
    },
    isFolderSelected(): boolean {
      return !(this.selectedFolders === undefined ||
      this.selectedFolders[0] === null ||
      this.selectedFolders[0] === undefined) &&
      this.selectedFolders[0].IsFolder;
    },
    xmlDisabled(): boolean {
      if (!this.isFolderSelected()) {
        return true;
      }
      return !this.configModel.AllowReload ||
        (!this.selectedFolders[0].IsLoadedApp &&
        !this.selectedFolders[0].IsLoadedSub);
    },
    enqueueSuccess(): void {
      this.getQueueData();
    },
    getListOfCheckedOptions(): string {
      return this.checkedOptions.join(", ");
    },
    selectOption(addOption: boolean, optionName: string): void {
      const index = this.checkedOptions.indexOf(optionName);
      if (addOption && index === -1) {
        this.checkedOptions.push(optionName);
      } else if (!addOption && index !== -1) {
        this.checkedOptions.splice(index, 1);
      }
    },
    setProcessButton(): void {
      if (this.selectedFolders.length === 0 || this.selectedFolders[0] === null || !this.selectedFolders[0].IsFolder) {
        this.removeButtonDisabled = true;
      } else {
        this.setProcessOptions();
      }
    },
    setProcessOptions(): void {
      const isLoaded = this.selectedFolders[0].IsLoadedApp || this.selectedFolders[0].IsLoadedSub;
      this.removeButtonDisabled = !isLoaded;
      if (isLoaded) {
        this.setProcessOptionsForLoadedFolder();
      } else {
        this.setProcessOptionsForUnloadedFolder();
      }
    },
    setProcessOptionsForLoadedFolder(): void {
      if (!this.configModel.AllowReload) {
        this.configModel.ValidateXMLStructure = false;
      }
      this.setFormat();
    },
    setProcessOptionsForUnloadedFolder(): void {
      this.configModel.ValidateXMLStructure = true;
      if (this.selectedFolders[0].IsSub) {
        this.setFormat(); // if the parent is loaded, then we know what format this must be
      } else {
        this.formatKnown = false;
      }
    },
    setFormat(): void {
      this.formatKnown = true;
      if (!this.selectedFolders[0].IsNeeS) {
        this.selectedFormat = "eCTD";
      } else if (this.selectedFolders[0].IsLoadedApp) {
        this.selectedFormat = "NeeS Application";
      } else {
        this.selectedFormat = "NeeS Sequence";
      }
    }
  },
  watch: {
    configModel: {
      handler(val) {
        this.selectOption(this.configModel.ValidatePdfs, ProcessOptions.ProcessPdfs);
        this.selectOption(this.configModel.ValidateChecksums, ProcessOptions.VerifyChecksums);
        this.selectOption(this.configModel.ValidateXMLStructure, ProcessOptions.XMLStructure);
      },
      deep: true
    },
    configurationInfo(configModel: ConfigurationModel): void {
      this.configModel = ConfigurationModel.FromObject(configModel);
    },
    selectedFolders(): void {
      this.setProcessButton();
    },
    queueData(): void {
      this.setProcessButton();
    }
  }
});
