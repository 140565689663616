import config from "@/config";
import ErrorHelper from "@/helpers/errorHelper";
import GlobalHelper from "@/helpers/globalHelper";
import { Company } from "common-vue-components/models/company";

class CompaniesService {
  public async getCompanies(): Promise<Company[]> {
    const response: Company[] = await GlobalHelper.http(`${config.resourceServer}/api/Companies/GetCompaniesForUser`, ErrorHelper.CompaniesGetError);
    return response.map(x => new Company(x.Id, x.Name, x.Expiration ? new Date(x.Expiration) : null));
  }
}

export const companiesService = new CompaniesService();
export default companiesService;
