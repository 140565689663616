


















import Vue from "vue";
import { mapState, mapMutations } from "vuex";
// eslint-disable-next-line no-unused-vars
import { BreadcrumbItem } from "../../models/breadcrumbs";

export default Vue.extend({
  computed: {
    ...mapState(["currentLocation"])
  },
  data(): {
      paths: BreadcrumbItem[],
      } {
    return {
      paths: []
    };
  },
  methods: {
    ...mapMutations(["addNewLocation", "changeLocationPlacement"]),
    setPath() {
      this.changeLocationPlacement();
      if (location.href.indexOf("#") > 0) {
        const url = decodeURIComponent(location.href.substr(location.href.indexOf("#") + 1));
        for (const folder of url.split("/")) {
          folder && this.addNewLocation({ Name: folder, IsFolder: true });
        }
      }
    }
  },
  mounted(): void {
    this.setPath();
    this.paths = this.currentLocation;

    window.addEventListener("popstate", () => {
      this.setPath();
    });
  },
  watch: {
    currentLocation(): void {
      this.paths = this.currentLocation;
    }
  }
});
