
























import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import $ from "jquery";
import ErrorHelper from "@/helpers/errorHelper";
import { ResponseTypes } from "@/helpers/enums";
import FileRenameResponse from "@/components/Storage/Rename/types/renameFileResponse";
import { InputValidationRules } from "vuetify";
import { EventBus } from "@/helpers/eventBus";
import GlobalHelper from "@/helpers/globalHelper";

export default Vue.extend({
  computed: {
    ...mapGetters(["currentLocationPath"]),
    ...mapState(["selectedFolders", "activeCompany"]),
    selectedFile(): any {
      return this.selectedFolders.length === 1 && !this.selectedFolders[0]?.IsFolder ? this.selectedFolders[0] : null;
    },
    nameRules(): InputValidationRules {
      return this.nameError ? [this.nameError] : [];
    }
  },
  data(): {
    dialog: boolean;
    name: string;
    nameError: string;
    submitting: boolean;
    } {
    return {
      dialog: false,
      name: "",
      nameError: "",
      submitting: false
    };
  },
  watch: {
    name: function() {
      this.nameError = "";
    }
  },
  methods: {
    ...mapMutations(["refresh"]),
    openDialog() {
      this.dialog = true;
      this.name = this.selectedFile.Name;
    },
    rename() {
      if (!this.activeCompany) {
        return;
      }

      this.submitting = true;
      this.nameError = "";

      $.ajax({
        url: "api/Storage/RenameFile",
        method: "POST",
        headers: GlobalHelper.getHeaders(),
        data: {
          companyId: this.activeCompany.Id,
          path: this.currentLocationPath,
          oldFileName: this.selectedFile.Name,
          newFileName: this.name
        }
      })
        .done((response: FileRenameResponse) => {
          if (!response.IsSuccess) {
            this.nameError = response.ErrorMessage;
          } else {
            ErrorHelper.addSnackbarMessage("File is renamed successfully", ResponseTypes.Success);
          }
        })
        .fail((error: any) => {
          ErrorHelper.addSnackbarMessage("The file renaming failed", ResponseTypes.Error, error.responseText, true);
          this.refresh();
        })
        .always(() => {
          this.submitting = false;
          if (!this.nameError) {
            this.dialog = false;
            this.refresh();
          }
        });
    },
    closeDialog() {
      this.dialog = false;
    }
  },
  mounted() {
    EventBus.$on("rename", () => {
      this.openDialog();
    });
  }
});
