// class for v-breadcrumb items

export class BreadcrumbItem {
  constructor(
        public text: string | number,
        public link: boolean,
        public currentObject: object | null,
        public previous: object | null,
        public href: string = "#",
        public to: string | object = "",
        public disabled: boolean = false,
        public exact: boolean = false
  ) {}
}
