















































































import Vue from "vue";
import StatusGrid from "../Grids/StatusGrid.vue";
import { mapState } from "vuex";

export default Vue.extend({
  components: {
    StatusGrid
  },
  computed: {
    ...mapState(["showActivityPage"])
  },
  mounted(): void {
    var today = new Date().toISOString().substr(0, 10);
    this.startDate = today;
    this.endDate = today;
  },
  methods: {
    formatForChild(d: string | null): Date {
      return new Date(d as string);
    },
    onKeypress(e: KeyboardEvent): void {
      if (e.key === "Enter") {
        this.appFilter = this.sourceAppNumber;
      }
    }
  },
  data(): {
    startDate: string | null,
    endDate: string | null,
    userFilter: any,
    appFilter: string,
    sourceAppNumber: string,
    endMenu: boolean,
    startMenu: boolean,
    openActivity: boolean,
    userDropdown: any,
    inputTimer: number | undefined,
    formattedStartDate : Date | null,
    formattedEndDate: Date | null
    } {
    return {
      startDate: "",
      endDate: "",
      userFilter: "current",
      appFilter: "",
      sourceAppNumber: "",
      endMenu: false,
      startMenu: false,
      openActivity: false,
      inputTimer: undefined,
      userDropdown: [
        { text: "Current User", name: "current" },
        { text: "All Users", name: "all" }
      ],
      formattedStartDate: null,
      formattedEndDate: null
    };
  },
  watch: {
    showActivityPage(): void {
      this.openActivity = this.showActivityPage;
    },
    startDate(): void {
      this.formattedStartDate = this.formatForChild(this.startDate);
    },
    endDate(): void {
      this.formattedEndDate = this.formatForChild(this.endDate);
    }
  },
  props: {
    autoRefresh: {
      type: Boolean
    }
  }
});
