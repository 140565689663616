var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align-content":"center"}},[_c('v-col',[_c('v-data-table',{staticClass:"files-table",attrs:{"loading":_vm.dataLoading,"loading-text":"Loading...","id":"filesTable","fixed-header":"","item-key":"Name","headers":_vm.headers,"show-select":"","items":_vm.items,"page":_vm.currentPage,"height":"67vh","items-per-page":_vm.itemsPerPage,"footer-props":{
           'items-per-page-text': '',
         },"search":_vm.search,"item-class":_vm.rowClass},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
         var item = ref.item;
return [(item.IsFolder)?_c('img',{staticStyle:{"display":"inline-block"},attrs:{"src":_vm.folderImage,"height":"22","width":"22"}}):_c('v-icon',{staticClass:"icon-size mr-1"},[_vm._v(_vm._s(_vm.GlobalHelper.getIconType(item.Name)))]),(item.IsFolder)?_c('a',{staticStyle:{"padding-left":"5px"},attrs:{"href":("#" + _vm.currentLocationPath + (_vm.currentLocationPath ? '/' : '') + (item.Name))}},[_vm._v(" "+_vm._s(item.Name)+" ")]):_c('span',[_vm._v(_vm._s(item.Name))])]}},{key:"item.Menu",fn:function(ref){
         var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-x":""},on:{"input":function($event){return _vm.onContextMenuInput($event, item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
         var on = ref.on;
         var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","disabled":!_vm.renameAvailable},on:{"click":function($event){return _vm.rename()}}},[_c('v-list-item-icon',{staticClass:"menu-list-item-icon"},[_c('v-icon',{attrs:{"disabled":!_vm.renameAvailable}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("RENAME")])],1)],1),_c('v-list-item',{attrs:{"link":"","disabled":!_vm.downloadAvailable},on:{"click":function($event){return _vm.download()}}},[_c('v-list-item-icon',{staticClass:"menu-list-item-icon"},[_c('v-icon',{attrs:{"disabled":!_vm.downloadAvailable}},[_vm._v("mdi-download")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("DOWNLOAD")])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":"","disabled":!_vm.deleteAvailable},on:{"click":function($event){return _vm.deleteObjects()}}},[_c('v-list-item-icon',{staticClass:"menu-list-item-icon"},[_c('v-icon',{attrs:{"disabled":!_vm.deleteAvailable}},[_vm._v("mdi-delete-outline")])],1),_c('v-list-item-content',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("DELETE")])],1)],1)],1)],1)]}},{key:"item.Status",fn:function(ref){
         var item = ref.item;
return [(item.Status)?[(item.Status === 'Processing')?_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"20","width":"1"}}):_vm._e(),(item.Status === 'Pending' || item.Status === 'Blocked')?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-progress-clock")]):_vm._e(),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.Status === 'Blocked' ? 'Pending' : item.Status))])]:(item.IsLoadedApp)?_c('span',[_vm._v(_vm._s(item.LoadedSubs)+" Sequences Loaded")]):(item.IsSub)?[(item.IsLoadedSub)?_c('span',[_c('v-icon',{attrs:{"color":"#43B52E"}},[_vm._v("mdi-checkbox-marked-circle")]),_vm._v("Loaded")],1):_c('span',[_c('v-icon',{attrs:{"color":"#696969"}},[_vm._v("mdi-checkbox-blank-circle-outline")]),_vm._v("Unloaded")],1)]:_c('span')]}},{key:"item.LastModifiedString",fn:function(ref){
         var item = ref.item;
return [(!item.IsFolder)?_c('span',[_vm._v(_vm._s(_vm.getHumanReadableDate(item.LastModifiedString)))]):_c('span')]}},{key:"item.Size",fn:function(ref){
         var item = ref.item;
return [(!item.IsFolder)?_c('span',[_vm._v(_vm._s(_vm.getHumanReadableSize(item.Size)))]):_c('span')]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }