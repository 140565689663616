import { AlertMessage } from "@/models/alertMessage";
import { EventBus } from "./eventBus";
import { Events } from "@/constants";

export default class ErrorHelper {
  public static addSnackbarMessage(message: string, color: string, errorMessage: string = "", showContactSupport: boolean = false, logId: number = 0, timeout: number | null = null): void {
    let timeoutValue = timeout ?? (color === "error" ? 0 : 5000);
    if (timeoutValue === 0) {
      timeoutValue = -1;
    }

    const alert = new AlertMessage(message, color, timeoutValue, errorMessage, logId);
    EventBus.$emit(Events.addSnackbar, alert, showContactSupport);
  }

  public static EnqueueProcessError: string = "Unable to enqueue the processing of this item. Please try again.";
  public static EnqueueRemoveError: string = "Unable to enqueue the removal of this item. Please try again.";
  public static ConfigurationSaveError: string = "Unable to save configuration. Please try again.";
  public static ConfigurationGetError: string = "Could not get configuration.";
  public static CompaniesGetError: string = "Could not get companies.";
  public static GridDataGetError: string = "Unable to get grid data.";
  public static StatusGetError: string = "Unable to get status.";
  public static QueueError: string = "Unable to get queue data";
  public static PermissionError: string = "Unable to get user permissions.";
  public static UserGuideGetError: string = "Could not get user doc.";
}
