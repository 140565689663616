

















import Vue from "vue";
import $ from "jquery";
import { mapGetters, mapMutations, mapState } from "vuex";

import UploadDialog, { UploadDialogRef } from 'common-vue-components/components/Dialogs/Upload/UploadDialog.vue';
import { UploadFileStatus } from 'common-vue-components/components/Dialogs/Upload/types/uploadFileStatus';
import {
  UploadParamsErrorFileInfo,
  UploadParamsSuccessFileInfo
} from 'common-vue-components/components/Dialogs/Upload/types/uploadParams';
import { UploadEventArgs } from 'common-vue-components/components/Dialogs/Upload/types/uploadEventArgs';
import { UploadFileEventArgs } from 'common-vue-components/components/Dialogs/Upload/types/uploadFileEventArgs';

import GlobalHelper from "@/helpers/globalHelper";
import ErrorHelper from "@/helpers/errorHelper";

import { ResponseTypes } from "@/helpers/enums";
import { StartFilesUploadRequest } from "@/components/Storage/Upload/types/startFilesUploadRequest";
import { CompleteFileUploadRequest } from "@/components/Storage/Upload/types/completeFileUploadRequest";
import { StartFileUploadResponse } from "@/components/Storage/Upload/types/startFileUploadResponse";
import { UploadStatus } from "common-vue-components/components/Dialogs/Upload/types/uploadStatus";

export default Vue.extend({
  components: { UploadDialog },
  computed: {
    ...mapGetters(["currentLocationPath"]),
    ...mapState(["currentLocation", "activeCompany"]),
    uploadDialog(): UploadDialogRef {
      return this.$refs.uploadDialog as any as UploadDialogRef;
    }
  },
  data(): {
    open: boolean;
    refreshOnClose: boolean;
    } {
    return {
      open: false,
      refreshOnClose: false
    };
  },
  methods: {
    ...mapMutations(["refresh"]),
    handleUploadClick() {
      this.open = true;
    },
    async handleUpload(e: UploadEventArgs) {
      const request: StartFilesUploadRequest = {
        companyId: this.activeCompany.Id,
        files: e.files.map(file => ({
          path: file.path,
          size: file.size
        })),
        currentLocationPath: this.currentLocationPath
      }
      
      let startFileUploadResponses: StartFileUploadResponse [] = []
      try {
        startFileUploadResponses = await $.ajax({
          url: "api/Storage/StartFilesUpload",
          method: "POST",
          headers: GlobalHelper.getHeaders(),
          data: request
        });
      } catch(error) {
        this.uploadDialog.setUploadStatus(UploadStatus.Error);
        ErrorHelper.addSnackbarMessage("The file(s) upload failed", ResponseTypes.Error, error.responseText, true);
        return;
      }
      
      this.refreshOnClose = true;
      
      try {
        await this.uploadDialog.upload({
          files: startFileUploadResponses.map(startFileUploadResponse => {
            const fileName = startFileUploadResponse.FileName;
            const filePath = startFileUploadResponse.FilePath;
            switch (startFileUploadResponse.ResponseType) {
              case ResponseTypes.Error:
                const errorFileInfo: UploadParamsErrorFileInfo = {
                  filePath,
                  fileName,
                  status: UploadFileStatus.Error,
                  message: startFileUploadResponse.ErrorMessage,
                };
                return errorFileInfo;
              case ResponseTypes.Success:
                const successFileInfo: UploadParamsSuccessFileInfo = {
                  filePath,
                  fileName,
                  status: UploadFileStatus.Success,
                  uploadId: startFileUploadResponse.UploadId,
                  partSize: startFileUploadResponse.PartSize,
                  partUrls: startFileUploadResponse.PartUrls
                };
                return successFileInfo;
            }
          })
        });
        this.uploadDialog.setUploadStatus(UploadStatus.Success);
        ErrorHelper.addSnackbarMessage('The file(s) uploaded successfully', ResponseTypes.Success);
      } catch {
        this.uploadDialog.setUploadStatus(UploadStatus.Error);
        ErrorHelper.addSnackbarMessage('The file(s) upload failed', ResponseTypes.Error, "", true);
      }
    },
    async handleUploadFile(e: UploadFileEventArgs) {
      const request: CompleteFileUploadRequest = {
        companyId: this.activeCompany.Id,
        uploadId: e.uploadId,
        path: e.path,
        partETags: e.eTags
      };
      
      try {
        await $.ajax({
          url: "api/Storage/CompleteFileUpload",
          method: "POST",
          headers: GlobalHelper.getHeaders(),
          data: request
        });
        this.uploadDialog.setFileUploadSuccess(e.path);
      } catch(error) {
        this.uploadDialog.setFileUploadError(e.path);
      }
    },
    handleUploadDialogClose() {
      this.open = false;
      
      if (this.refreshOnClose) {
        this.refreshOnClose = false;
        this.refresh();
      }
    }
  }
});
