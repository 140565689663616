export class AlertMessage {
    public Message: string;
    public Color: string;
    public DismissTime: number;
    public ErrorMessage: string;
    public LogId: number;

    public constructor(message: string, color: string, dismissTime: number, errorMessage: string = "", logId: number = 0) {
      this.Message = message;
      this.Color = color;
      this.DismissTime = dismissTime;
      this.ErrorMessage = errorMessage;
      this.LogId = logId;
    }
}
