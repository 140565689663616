import store from "@/store";
import { BreadcrumbItem } from "@/models/breadcrumbs";
import ErrorHelper from "./errorHelper";
import $ from "jquery";
import { QueueItem } from "@/models/queueItem";
import oktaAuth from 'common-vue-components/oktaAuth';

// class to be used for functional methods with no specific owner
export default class GlobalHelper {
  public static IsIe(): boolean {
    const ua: string = window.navigator.userAgent;
    const msie: number = ua.indexOf("MSIE ");
    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
  };
  
  public static getAuthorization() {
    return `Bearer ${oktaAuth.getAccessToken()}`;
  }

  public static getHeaders() {
    return {
      Authorization: this.getAuthorization()
    };
  }

  public static async http(request: RequestInfo, errorMessage: string): Promise<any> {
    return new Promise(resolve => {
      fetch(request, {
        method: "GET",
        headers: this.getHeaders()
      })
        .then((res) => {
          if (res.status === 500) {
            ErrorHelper.addSnackbarMessage(errorMessage, "error", res.statusText, true);
            return "";
          }
          return res.text();
        })
        .then((text) => text.length ? JSON.parse(text) : null)
        .then(body => {
          resolve(body);
        });
    });
  };

  public static httpWithObject(url: string, data: Object, errorMessage: string, successFunction: any): void {
    $.ajax({
      url: url,
      headers: this.getHeaders(),
      data: data
    }).done((result: any) => {
      if (result.status === 500) {
        ErrorHelper.addSnackbarMessage(errorMessage, "error", result.statusText, true);
      } else {
        return successFunction();
      }
    });
  }

  // Find the items in current that are not in incoming based on QueueId
  public static queueDiffs(incoming: QueueItem[], current: QueueItem[]) {
    return current.filter(this.queueItemQueueIdComparer(incoming));
  }

  private static queueItemQueueIdComparer(otherArray: QueueItem[]) {
    return function(current: QueueItem) {
      return otherArray.filter(function(other) {
        return other.QueueId === current.QueueId;
      }).length === 0;
    };
  }

  public static queueItemPathComparer(path: string, parentPath: string) {
    return function(current: QueueItem) {
      return current.Path.includes(path) || (parentPath.length > 0 && current.Path.substring(current.Path.indexOf("/") + 1) === parentPath && current.Type === "Application");
    };
  }

  public static httpPost(url: string, data: Object): any {
    return $.ajax({
      url: url,
      headers: this.getHeaders(),
      data: data,
      type: "POST"
    });
  }

  public static toQueryString(obj: any): string {
    return "?" + Object.keys(obj).map(key => key + "=" + obj[key]).join("&");
  }

  public static addToClipboard(textToCopy: string): boolean {
    if (navigator.clipboard !== null) {
      navigator.clipboard.writeText(textToCopy);
      return true;
    }

    let successful = false;
    const el = document.createElement("textarea");
    el.value = textToCopy;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999";
    document.body.appendChild(el);
    el.select();

    try {
      successful = document.execCommand("copy");
    } catch (err) {
      document.body.removeChild(el);
      return false;
    }
    const clipboardData = (window as any).clipboardData;
    if (clipboardData && clipboardData.getData) {
      if (!clipboardData.getData("Text")) {
        successful = false;
      }
    }
    document.body.removeChild(el);
    return successful;
  };

  public static ConstructPathFromCurrentLocation(): string {
    let path = this.ConstructPath();
    if (path.length !== 0) {
      path = "/" + path;
    }
    return path;
  };

  public static ConstructPath(): string {
    return store.state.currentLocation.slice(1).map((elem: BreadcrumbItem) => {
      return elem.text;
    }).join("/");
  }

  public static debounce(func: Function, wait: number): Function {
    let timerId: number | null = null;
    return () => {
      if (timerId == null) {
        timerId = setTimeout(() => (timerId = null), wait);
        func.apply(this);
      }
    };
  }

  public static getIconType(name: string): string {
    const defaultIcon: string = "mdi-file-outline";
    const indexOfPeriod: number = name.indexOf(".");
    if (indexOfPeriod < 0) {
      return defaultIcon;
    }
    const extension: string = name.slice(indexOfPeriod);

    switch (extension) {
      case ".pdf":
        return "mdi-file-pdf-outline";
      case ".xml":
        return "mdi-file-code-outline";
      case ".doc":
      case ".docx":
        return "mdi-file-word-outline";
      case ".xls":
      case ".xlsx":
        return "mdi-file-excel-outline";
      case ".jpg":
      case ".jpeg":
      case ".png":
      case ".gif":
        return "mdi-file-image-outline";
      default:
        return "mdi-file-document-outline";
    }
  }
}
