var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"elevation-2 contain",attrs:{"light":"","height":50,"id":"mainNavbar"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-cloud-print-outline")]),_c('v-toolbar-title',{staticClass:"ml-4"},[_vm._v("Load")]),_c('v-spacer'),(_vm.hasAccess)?_c('CompanySelector',{attrs:{"companies":_vm.companies,"currentCompany":_vm.activeCompany,"isLoading":_vm.areCompaniesLoading},on:{"onCompanySelected":_vm.changeActiveCompany}}):_vm._e(),_c('div',{staticClass:"hidden-sm-and-down"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.canAccessConfiguration),expression:"canAccessConfiguration"}],attrs:{"icon":"","text":"","id":"configurationButton","aria-label":"Configuration","disabled":!_vm.activeCompany},on:{"click":_vm.openConfiguration}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}])},[_c('span',[_vm._v("Configuration")])]),_c('v-menu',{attrs:{"offset-y":"","min-width":"115px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.nineDot},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","id":"navbarCloudAppsSelectButton","aria-label":"Show Cloud Applications"}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-apps")])],1)]}}],null,true)},[_c('span',[_vm._v("Cloud applications")])])]}}]),model:{value:(_vm.nineDot),callback:function ($$v) {_vm.nineDot=$$v},expression:"nineDot"}},[_c('NineDot',{attrs:{"current":"Load"}})],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.info},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"navbarHelpMenuButton","aria-label":"Help"}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Help")])])]}}]),model:{value:(_vm.info),callback:function ($$v) {_vm.info=$$v},expression:"info"}},[_c('Info')],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"navbarLogoutButton","aria-label":"Log Out"},on:{"click":_vm.signOut}},on),[_c('v-icon',[_vm._v("mdi-logout-variant")])],1)]}}])},[_c('span',[_vm._v("Sign Out")])])],1),_c('div',{staticClass:"hidden-md-and-up"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-md-and-up",attrs:{"icon":"","id":"navbarNavdrawerButton","aria-label":"Open Navigation Drawer"},on:{"click":function($event){$event.stopPropagation();return _vm.showHideDrawer($event)}}},on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('span',[_vm._v("More...")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }