
















































import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import Info from "../Dropdowns/Info.vue";
import NineDot from "common-vue-components/components/Dropdowns/NineDot.vue";

export default Vue.extend({
  components: {
    Info,
    NineDot
  },
  computed: {
    ...mapState(["drawerState", "activeCompany", "canAccessConfiguration"])
  },
  data(): {
        drawer: boolean;
        } {
    return {
      drawer: false
    };
  },
  methods: {
    ...mapMutations(["changeDrawerState", "changeConfigurationDialogState"]),
    signOut(): void {
      this.$auth.signOut();
    },
    openConfiguration(): void {
      this.changeConfigurationDialogState();
    }
  },
  watch: {
    drawerState(): void {
      this.drawer = this.drawerState;
    },
    drawer(): void {
      if (this.drawer === false) {
        this.changeDrawerState();
      }
    }
  }
});
