





























































































import Vue from "vue";
import { mapState, mapMutations, mapActions } from "vuex";
import CompanySelector from "common-vue-components/components/Dropdowns/CompanySelector.vue";
import NineDot from "common-vue-components/components/Dropdowns/NineDot.vue";
import Info from "../Dropdowns/Info.vue";

export default Vue.extend({
  components: {
    NineDot,
    Info,
    CompanySelector
  },
  computed: {
    ...mapState(["hasAccess", "companies", "activeCompany", "areCompaniesLoading", "canAccessConfiguration"]),
  },
  data(): {
        nineDot: boolean,
        info: boolean
        } {
    return {
      nineDot: false,
      info: false
    };
  },
  methods: {
    ...mapActions(["changeActiveCompany"]),
    ...mapMutations(["changeDrawerState", "changeConfigurationDialogState"]),
    openConfiguration(): void {
      this.changeConfigurationDialogState();
    },
    signOut() {
      this.$auth.signOut();
    },
    showHideDrawer(): void {
      this.changeDrawerState();
    }
  }
});
