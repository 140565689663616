













































































































import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import GlobalHelper from "../../helpers/globalHelper";
// eslint-disable-next-line no-unused-vars
import { ConfigurationModel } from "../../models/configurationModel";
import ErrorHelper from "../../helpers/errorHelper";

export default Vue.extend({
  computed: {
    ...mapState(["configurationDialogState", "allowReload", "validatePdfs", "activeCompany"])
  },
  data(): {
        configurationDialog: boolean,
        loading: boolean,
        rules: any,
        valid: boolean,
        errorMessage: string,
        configModel: ConfigurationModel
        } {
    return {
      configurationDialog: false,
      loading: false,
      rules: [(field: string) => !!field || "This field is required"],
      valid: false,
      errorMessage: "",
      configModel: ConfigurationModel.FromObject({})
    };
  },
  methods: {
    ...mapMutations(["changeConfigurationDialogState", "changeConfigurationInfo", "changeAllowReload", "changeValidatePdfs", "changeLocationPlacement"]),
    async saveConfiguration(): Promise<void> {
      if (!this.activeCompany) {
        return;
      }
      this.loading = true;
      let configSave: string = "";
      try {
        configSave = await GlobalHelper.httpPost(`api/Configuration/SaveConfiguration?companyId=${this.activeCompany.Id}`, this.configModel);
      } catch (e) {
        ErrorHelper.addSnackbarMessage("Failed to save configuration.", "error", e.responseText, true);
      }
      if (configSave.length > 0) {
        this.errorMessage = configSave;
        this.loading = false;
        return;
      }
      this.errorMessage = "";
      this.loading = false;
      this.configurationDialog = false;
      this.changeConfigurationInfo(this.configModel);
      this.changeLocationPlacement();
    }
  },
  watch: {
    async configurationDialogState(): Promise<void> {
      let configuration: ConfigurationModel | null = null;

      if (this.activeCompany) {
        configuration = await GlobalHelper.http(`api/Configuration/GetConfiguration?companyId=${this.activeCompany.Id}`, ErrorHelper.ConfigurationGetError);
      }

      if (configuration == null) {
        return;
      }
      this.configModel = configuration;
      this.configurationDialog = this.configurationDialogState;
    },
    configurationDialog(): void {
      if (!this.configurationDialog) {
        this.changeConfigurationDialogState();
      } else {
        this.errorMessage = "";
      }
    }
  }
});
